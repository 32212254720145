import React, { useEffect, useState } from "react";
import { useMedia } from "use-media";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/UsecaesHeader";
import Container from "../../components/chat-channel/Container";
import Cards from "../../components/chat-channel/two-cards";
import ArrowRightICon from "../../components/common/Icons";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {
  autoDesigner,
  chatChannel,
  logs,
  marketplace,
  dialog,
  onprem_icon,
  c_ai,
} from "./chatbot-builder";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { GoToPopup } from "../homepage";

const aboveFoldImage = require("../..//assets/images/usecases/onprem/onprem_header.png");
const aboveFoldImage_mob = require("../..//assets/images/usecases/onprem/onprem_header.png");
const sec_1 = require("../..//assets/images/usecases/onprem/onprem_sec_1.png");
const sec_2 = require("../..//assets/images/usecases/onprem/onprem_sec_2.png");

const desktop = require("../..//assets/images/usecases/onprem/on-prem_howitworks.png");
const mobile = require("../..//assets/images/usecases/mobile/on-prem_howitworks_mob.png");

export const cardOne = require("../..//assets/images/usecases/onprem/on-prem_feature_icon_1.png");
export const cardTwo = require("../..//assets/images/usecases/onprem/on-prem_feature_icon_2.png");
export const cardThree = require("../..//assets/images/usecases/onprem/on-prem_feature_icon_3.png");
export const cardFour = require("../..//assets/images/usecases/onprem/on-prem_feature_icon_4.png");

export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");

const ldSchema = {
  "@context": "https://schema.org/",
  "@type": "HowTo",
  name: "How workativ Assistant on-prem connect Works",
  description:
    "Establish a secure connectivity between your on-premise apps and Workativ",

  step: [
    {
      "@type": "HowToStep",
      text: "Secured connection between Workativ and your internal app",
      name: "HTTPS calls",
    },
    {
      "@type": "HowToStep",
      text: "Easy-to-connect architecture",
      name: "Workativ Platform",
    },
    {
      "@type": "HowToStep",
      text: "Create automations easily between cloud and on-prem apps",
      name: "Customer Network",
    },
  ],
};

export const otherFeatures = [
  {
    image: dialog,
    alt: "Chatbot Builder​​",
    header: "Chatbot Builder​​",
    content:
      "Use no-code visual canvas to create engaging conversations for your chatbot easily in minutes.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: c_ai,
    alt: "Conversational AI Platform",
    header: "Conversational AI Platform",
    content:
      "Learn more on workativ assistants’ conversational ai platform capabilities.",
    link: "/conversational-ai-platform/conversational-ai",
  },
  {
    image: autoDesigner,
    alt: "Chatbot Automation​",
    header: "Chatbot Automation​",
    content:
      "Add IT or HR workflow automations to chatbot through app integration and pre-built app workflows in few clicks.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: knowledgeAi,
    alt: "Knowledge AI",
    header: "Knowledge AI​",
    new: true,
    content:
      "Knowledge AI leverages the power of LLMs and Generative AI to provide contextually relevant responses to user queries.",
    link: "/conversational-ai-platform/knowledge-ai",
  },
  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: chatChannel,
    alt: "Omnichannel",
    header: "Omnichannel",
    content:
      "Deploy chatbot on your favorite collaboration apps like Teams, Slack, or as Chat Widget easily in few clicks.",
    link: "/conversational-ai-platform/chatbot-channels",
  },
  {
    image: chabotApproval,
    alt: "Chatbot Approvals",
    header: "Chatbot Approval​s",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },
  {
    image: marketplace,
    alt: "Chatbot Marketplace",
    header: "Chatbot Marketplace",
    content:
      "Download pre-built chatbots, 100+ app integrations, and 1000+ app workflows in seconds and go live instantly.",
    link: "/conversational-ai-platform/app-workflow-template-marketplace",
  },
  {
    image: logs,
    alt: "Chatbot Analytics & Performance",
    header: "Chatbot Analytics & Performance",
    content:
      "Gather insight to craft better end user experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/chatbot-analytics-performance",
  },
];

export const cardData = [
  {
    image: cardOne,
    header: "Quick Installation",
    altImage: "Quick Installation",
    content: `Detailed step-by-step instructions are available for a quick installation and configuration of on-prem agent in your network.`,
    linkAddress: null,
    backgroundClass: "pad_0",
  },
  {
    image: cardTwo,
    header: "TLS Persistence",
    altImage: "TLS Persistence",
    content: `Maintains a persistent and secure tunnel connection between the workativ agent and the workativ gateway with outbound socket via HTTPS.`,
    linkAddress: null,
    backgroundClass: "pad_0",
  },
  {
    image: cardThree,
    altImage: "Scalable Load-Request Handling",
    header: "Scalable Load-Request Handling",
    content: `Workativ uses load-balancing cluster mode to have as many parallel workers as number of virtual CPUs`,
    linkAddress: null,
    backgroundClass: "pad_0",
  },
  {
    image: cardFour,
    altImage: "No Firewall Changes",
    header: "No Firewall Changes",
    content: `Workativ on-prem connect is installable in the DMZ or in an inner network zone without changes to your firewall.`,
    linkAddress: null,
    backgroundClass: "pad_0",
  },
];

export default function HomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free On-prem App Connector | Hybrid App Connector | Workativ Assistant Chatbot"
        description="Connect On-Prem Apps Securely with Workativ – Use an on-prem connector to automate workflows with over 50 apps via chatbot. Secure your automation today!"
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={aboveFoldImage}
        ogTitle="Free On-prem App Connector | Hybrid App Connector | Workativ Assistant Chatbot"
        ogDescription="Connect On-Prem Apps Securely with Workativ – Use an on-prem connector to automate workflows with over 50 apps via chatbot. Secure your automation today!"
        schema={ldSchema}
      />
      <Container additionalClass={"feature-display-none"}>
        <section className="onprem_connect">
          <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
            <TopFormWithImage
              image={aboveFoldImage}
              altImage={"Conversation AI Platform"}
              image_mob={aboveFoldImage_mob}
              additionalClassSection={"features_indvidual_dec"}
              additionalClassImage={"width-100"}
            >
              <TopFormWithImage.Header>
                Secured connectivity between chatbot and your on-prem apps
              </TopFormWithImage.Header>
              <RequestForm isFooterForm={false} />
            </TopFormWithImage>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            <div className="features_container_left mobile-padding-40px">
              <section className=" features_img_left pl-10-tage landing_page trial_page_img bg_trial_page">
                <div className="container">
                  <div className="row">
                    <div className="center_feature_wrapper">
                      <h2 className="font-section-header">
                        What is On-prem Connect
                      </h2>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        Workativ’s On-prem Connect provides a secure connection
                        between our platform and your on-prem apps behind your
                        corporate firewall. For increased level of security,
                        workativ relays HTTPS calls to workativ agent behind
                        your corporate firewall to execute workflows with your
                        on-prem apps.
                      </p>
                    </div>

                    <div className="col-md-6 feature_page_img_right ">
                      <img
                        loading="lazy"
                        src={sec_1}
                        alt="easy to setup on-prem connect"
                      />
                    </div>
                    <div className="col-md-6 feature_page_content_left">
                      <h3 className="font-section-header">
                        Easy-to-connect setup
                      </h3>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        Connection to your network and on-prem apps is an easy,
                        uncomplicated process. Workativ provides an installer
                        (workativ agent) that can be set up in your windows,
                        linux or Mac machine to establish a secure connectivity
                        with workativ using a secure access token, ensuring that
                        the connection being established is secure.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="bg_conversation_ai">
              <section className=" features_img_left pl-10-tage landing_page trial_page_img">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 feature_page_content_left">
                      <h3 className="font-section-header">
                        Execute workflows for on-prem apps from chatbot
                      </h3>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        With workativ’s on-prem connect, you can set up
                        workflows between cloud and on-prem apps and execute
                        from chatbot securely without a hitch. By integrating
                        these apps on our platform, you bridge the gap and
                        create hybrid workflow automations to execute tasks
                        securely.
                      </p>
                    </div>
                    <div className="col-md-6 feature_page_img_right ">
                      <img
                        loading="lazy"
                        className
                        src={sec_2}
                        alt="create secured workflows to automate tasks between cloud and on-prem apps"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section className="automation_steps" id={"section_3"}>
              <div className="container">
                <h2 className="font-section-header mb-2">How it works</h2>
                <p className="font-section-normal-text-testimonials line-height-18 padding-center-new">
                  When a workflow is triggered from chatbot, a connection is
                  initiated between workativ gateway and workativ agent
                  (installed behind your corporate firewall). The workativ agent
                  then makes a request call to the on-prem app and the response
                  is sent back through the agent to the workativ platform.
                </p>

                <picture>
                  <source media="(max-width:768px)" srcSet={mobile} />
                  <img
                    loading="lazy"
                    src={desktop}
                    alt="on-prem connect architecture - workativ"
                  />
                </picture>
              </div>
            </section>

            <div className="onprem_cards">
              <Cards header={"s"} cards={cardData} />
            </div>

            <section className="cards_features">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                    <div className="build_needs">
                      <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                        <h2 className="font-section-header">
                          Explore More Features
                        </h2>
                        <p className="font-section-normal-text-testimonials line-height-18 container-width-padding">
                          Our 3-in-1 support automation platform combines the
                          power of Gen AI chatbot for automating chats, App
                          workflow automation for auto-resolution of issues, and
                          Shared Live Inbox for seamless live chat interactions.
                          And more…!
                        </p>
                      </div>
                    </div>
                    <section className="whitepaper_cards">
                      <div className="container-fluid p-0">
                        <ul className="cards">
                          {otherFeatures.map((data) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    loading="lazy"
                                    src={data.image}
                                    alt={data.alt}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title font-section-normal-text-medium">
                                    {data.header}
                                    {data.beta ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        Beta
                                      </span>
                                    ) : null}
                                    {data.new ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        New
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className="card__text font-section-small-text pb-0">
                                    {data.content}
                                  </p>
                                  <div class="card_link_landing">
                                    <a
                                      class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                      href={data.link}
                                    >
                                      Know more&nbsp; &#8594;
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
            {/* <GoToPopup /> */}
            <div className="isfooter_form">
              <RequestForm isFooterForm={true} />
            </div>
          </Layout>
        </section>
      </Container>
    </>
  );
}
