import React, { useEffect } from "react";
import * as $ from "jquery";
import { RequestForm } from "../form";

RegisterSection.MainHeader = ({ children }) => {
  return <h1>{children}</h1>;
};

RegisterSection.SubHeader = ({ children }) => {
  return <p>{children}</p>;
};

RegisterSection.FormWithContent = ({ children }) => {
  return (
    <div className="main-slider-left">
      {children}
      <RequestForm isFooterForm={false} />
    </div>
  );
};

export default function RegisterSection({
  children,
  rightImage,
  videoToken,
  backgroundColor,
  altImage
}) {
  useEffect(() => {
    $("body").append(
      '\n <div class="lightbox">\n <a href="#lightbox" class="lightbox-close lightbox-toggle"><span>X</span></a>\n <div class="lightbox-container">\n <div class="row">\n <div class="col-sm-12 lightbox-column">\n\n </div>\n </div>\n </div>\n </div>\n '
    );

    $(".lightbox-toggle").on("click", function(event) {
      event.preventDefault();
      $(".lightbox").fadeToggle("fast");
      var context = $(event.currentTarget).attr("data-lightbox-type");
      var content = $(event.currentTarget).attr("data-lightbox-content");
      if (context == "video") {
        $(".lightbox-column").append(
          `\n<div class="lightbox-video">\n<iframe src="${content}" frameborder="0" &autoplay=1 ></iframe>\n</div>\n`
        );
      } else if (context == "image") {
        $(".lightbox-column").append(
          `\n<img src="${content}" class="img-" frameborder="0" allowfullscreen>\n`
        );
      }
    });

    $(".lightbox-close").on("click", function(event) {
      event.preventDefault();
      $(".lightbox-column > *").remove();
    });
  }, []);
  return (
    <section
      className={`${"assistant_slider beta-main-slider trial_mb_80 bg_header_trial"} ${
        backgroundColor ? backgroundColor : "bg_slider_home"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 trial_header_left">
            {children}
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 cont_right">
            <div className="main-slider-right assistant_right_img beta-slider">
              <div className="video_show">
                <div className="nav-item main-download-broucher">
                  {videoToken === "__NO_VIDEO_AVAILABLE__" ? (
                    <img className src={rightImage} alt={altImage} />
                  ) : (
                    <div className="video_append">
                      <a
                        href="#lightbox"
                        className="lightbox-toggle nav-link url_manipulation"
                        data-lightbox-type="video"
                        data-lightbox-content={`https://www.youtube-nocookie.com/embed/${videoToken}?autoplay=1`}
                      >
                        <img className src={rightImage} alt={altImage} />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
